import { API_RESPONSE_CODE } from "../../constants/configuration";
import { resetUser, setIsAccountSuspended } from "../../appActions";
import { mapObjectKeysToCamelCase } from "../commonService";
import { FORBIDDEN, SIGNIN_PATH } from "../../constants/Routes";

import store from "../../../store";

const redirect = (url) => {
  window.location = url;
};

export const handleApiResponse = (xhr, isBlob, resolve, reject) => {
  let isResolved = false;

  switch (xhr.status) {
    case API_RESPONSE_CODE.UNAUTHENTICATED:
      if (!store.getState().app.isAuthenticated) {
        redirect(SIGNIN_PATH);
      } else store.dispatch(resetUser());
      break;
    case API_RESPONSE_CODE.SUSPENDED:
      store.dispatch(setIsAccountSuspended(true));
      break;
    case API_RESPONSE_CODE.UNAUTHORIZED:
      redirect(FORBIDDEN);
      break;
    default:
      if (
        xhr.status >= API_RESPONSE_CODE.SUCCESS &&
        xhr.status < API_RESPONSE_CODE.MULTIPLE_RESPONSE_CHOICES
      ) {
        resolve(isBlob ? xhr.response : mapObjectKeysToCamelCase(xhr.response));
        isResolved = true;
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
  }

  return isResolved;
};
