import { camelCaseToPascalCase } from "../commonService";

// Hash Query Parameter  module

/*  All the functionalities related to Hash Parameters
    in the query. 
*/
export function getFormattedKeyValue(
  key,
  value,
  convertToPascalCase = true,
  encodeUrlKeys = true,
) {
  return `${convertToPascalCase ? camelCaseToPascalCase(key) : key}=${
    encodeUrlKeys ? encodeURIComponent(value) : value
  }`;
}
// returns new url with query params
export function encodeUrl(
  apiUrl,
  params,
  convertToPascalCase = true,
  encodeUrlKeys = true,
) {
  if (typeof params === "undefined") return apiUrl;

  apiUrl += params ? "?" : "";
  let hasParams = false;
  for (const key in params) {
    if (
      Object.prototype.hasOwnProperty.call(params, key) &&
      typeof params[key] !== "undefined" &&
      params[key] !== null &&
      params[key].toString().trim().length
    ) {
      if (hasParams) apiUrl += "&";
      if (typeof params[key] === "object" && params[key].length > 1) {
        params[key].map((value, index) => {
          apiUrl += getFormattedKeyValue(
            key,
            value,
            convertToPascalCase,
            encodeUrlKeys,
          );
          if (index !== params[key].length - 1) apiUrl += "&";
        });
      } else {
        apiUrl += getFormattedKeyValue(
          key,
          params[key],
          convertToPascalCase,
          encodeUrlKeys,
        );
      }
      hasParams = true;
    }
  }

  return apiUrl;
}

// Remove passed key from sourceURL and return new URL
export function removeParam(key, sourceURL) {
  let newURL = sourceURL.split("?")[0];
  let param;
  let params_arr = [];
  const queryString =
    sourceURL.indexOf("?") !== -1
      ? sourceURL.substring(newURL.length + 1, sourceURL.length)
      : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (let i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param.toLowerCase() === key.toLowerCase()) params_arr.splice(i, 1);
    }
    newURL += params_arr.length ? `?${params_arr.join("&")}` : "";
  }
  return newURL;
}

// Remove passed key from sourceURL and return new URL
export function querySearch(sourceURL) {
  let queryParamsObject = {};
  const queryString =
    sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    const queryParamsString = decodeURI(queryString).replace(/"/g, '\\"');
    queryParamsObject = getJsonObjectForQueryString(queryParamsString);
  }
  return queryParamsObject;
}

function getJsonObjectForQueryString(queryString) {
  const result = {};
  queryString.split("&").map((param) => {
    const paramObject = param.split("=");
    result[paramObject[0]] =
      typeof paramObject[1] === "undefined" ? "" : paramObject[1];
  });
  return result;
}

export function getParamValue(key, sourceURL) {
  let queryParamsObject = {};
  const queryString =
    sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    const queryParamsString = decodeURI(queryString).replace(/"/g, '\\"');
    queryParamsObject = getJsonObjectForQueryString(queryParamsString);
  }
  return queryParamsObject[key];
}
