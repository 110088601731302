import { mapObjectKeysToPascalCase } from "../commonService";
import { getCookie } from "../cookieHelper";
import { getConfiguration, getNestedConfiguration } from "../configurationUtil";
import { handleApiResponse } from "./apiResponseHandler";

const ajaxCall = function (
  options,
  withCredentials,
  isBlob,
  isFileUpload,
  isMicroServiceApiCall = false,
) {
  const requestToken = document.getElementById("hdnReqToken")
    ? document.getElementById("hdnReqToken").val()
    : " ";
  const xhr = new XMLHttpRequest();
  let isResolved = false;

  // creating promise for the xhr call
  const promise = new Promise((resolve, reject) => {
    if (isMicroServiceApiCall)
      xhr.open(
        options.method,
        getConfiguration("microServiceApiUrl") + options.url,
      );
    else {
      const market = getCookie("MarketPreference");
      let apiUrl = market ? getNestedConfiguration("marketWiseApiUrl",market): null;
      apiUrl = apiUrl || getConfiguration("apiUrl");
      xhr.open(options.method, apiUrl + options.url);
    }

    // setting request headers for the call
    xhr.setRequestHeader("X-HiddenToken", requestToken);
    if (isFileUpload && options.fileNameArray)
      xhr.setRequestHeader("X-FileName", options.fileNameArray);

    if (options.method === "POST" || options.method === "PUT")
      if (!isFileUpload)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    // setting the response type to json/blob
    xhr.responseType = isBlob ? "blob" : "json";

    // setting cross domain calls to be true using withCredentials functionality
    if (
      options.url.includes("plus/") ||
      withCredentials ||
      isMicroServiceApiCall
    ) {
      xhr.withCredentials = true;
    }
    // if (isFileUpload)
    //   xhr.upload.onprogress = function(e) {
    //     if (e.lengthComputable) {
    //       let percentcomplete = (e.loaded / e.total) * 100
    //       //console.log(percentcomplete + '% uploaded');
    //     }
    //   }

    xhr.send(options.data);
    xhr.onload = function () {
      isResolved = handleApiResponse(xhr, isBlob, resolve, reject);
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
  });

  // adding abort functionality to my xhr call so that we dont wait for the promise any more.
  promise.abort = function () {
    xhr.abort();
  };

  promise.isResolved = function () {
    return isResolved;
  };

  return promise;
};

// API GET call
export const apiGet = function (
  url,
  withCredentials,
  isBlob = false,
  isFileUpload = false,
  isMicroServiceApiCall = false,
) {
  const options = {
    method: "GET",
    url,
  };
  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API POST call
export const apiPost = function (
  url,
  data,
  withCredentials = true,
  isBlob = false,
  isFileUpload = false,
  isMicroServiceApiCall = false,
) {
  const options = {
    method: "POST",
    url,
    data: JSON.stringify(data),
  };
  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API PUT call
export const apiPut = function (
  url,
  data,
  withCredentials = true,
  isBlob = false,
  isFileUpload = false,
  isMicroServiceApiCall = false,
) {
  const options = {
    method: "PUT",
    url,
    data: JSON.stringify(mapObjectKeysToPascalCase(data)),
  };
  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API DELETE call
export const apiDelete = function (
  url,
  withCredentials = false,
  isBlob = false,
  isFileUpload = false,
  isMicroServiceApiCall = false,
) {
  const options = {
    method: "DELETE",
    url,
  };

  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API Download File
export const apiDownload = function (
  url,
  withCredentials = false,
  isBlob = true,
  isFileUpload = false,
  isMicroServiceApiCall = false,
) {
  const options = {
    method: "GET",
    url,
  };

  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API Upload File
export const apiUpload = function (
  url,
  files,
  withCredentials = false,
  isBlob = false,
  isFileUpload = true,
  isMicroServiceApiCall = false,
) {
  const formData = new FormData();
  const fileNameArray = [];
  for (let fileCount = 0; fileCount < files.length; fileCount++) {
    formData.append(files[fileCount].name, files[fileCount]);
    fileNameArray.push(files[fileCount].name);
  }

  const options = {
    method: "POST",
    url,
    data: formData,
    fileNameArray: fileNameArray,
  };
  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};

// API Upload File Old
export const apiInstantUpload = function (
  url,
  formData,
  fileNameArray,
  withCredentials = false,
  isBlob = false,
  isFileUpload = true,
  isMicroServiceApiCall = false,
  addOnFormData = {},
) {
  formData.set("FormData", JSON.stringify(addOnFormData));

  const options = {
    method: "POST",
    url,
    data: formData,
    fileNameArray: fileNameArray,
  };
  return ajaxCall(
    options,
    withCredentials,
    isBlob,
    isFileUpload,
    isMicroServiceApiCall,
  );
};
